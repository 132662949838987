
@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;500;700&family=Roboto:wght@100&display=swap');



body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bg-plane {
  background-image: url("../src/img/plane.jpg");
  background-size: cover;
  border-radius: 10px;
 
}
.bg-plane-enter {
  background-image: url("../src/img/plane-enter.jpg");
  background-size: cover;
  border-radius: 10px;
 
}

.bg-dashboard {
  background-image: url("../src/img/background.png");
  background-repeat: no-repeat;
  background-position:top;
}

.bg-showpage {
  background-image: url("../src/img/beams-home.jpeg");
  background-repeat: no-repeat;
  background-position:center;
}

.bg-header {
  background-image: url("../src/img/blur-cyan.d28a5585.png");
  background-repeat: no-repeat;
  background-position: center left;
}

:where(.css-dev-only-do-not-override-1m62vyb).ant-form-item .ant-form-item-label {
  text-align: start;
}





:where(.css-dev-only-do-not-override-1m62vyb).ant-menu-light {
  background:transparent;
}

nav {
  border-bottom:.5px solid #bec5cd
}
